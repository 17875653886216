.header {
  &__subtitle {
    @extend .h--subtitle;
    margin-bottom: $gutter--sm;
    line-height: 1.1;
    opacity: 0.7;
  }

  &__title {
    color: inherit;
    margin-bottom: $gutter--lg * 1.25;

    a {
      font-size: $font-size--md;
    }

    span {
      overflow: hidden;
    }

  }

  &--landing {
    color: color(white, base);

    .header__title {
    }

    .header__subtitle {
      color: inherit;
      opacity: 0.3;
    }
  }

}
