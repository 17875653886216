
@use "sass:math";
// Assets Paths
$asset-path: '/assets/';
$image-path: '#{$asset-path}images/';
$icon-path: '#{$asset-path}images/icon/';
$audio-path: '#{$asset-path}audio/';
$video-path: '#{$asset-path}video/';
$font-path: '#{$asset-path}fonts/';

// Colors
$color--white: #fff;
$color--black: #1F1F1F;
$color--orange: #F95D2C;
$color--blue: #009BD4;
$color--grey: #BCBCBC;
$color--yellow: #FFC700;

$box-shadow--base: 0 2px 9px 0 rgba($color--black, 0.2);

$colors: (
    white: (
        base:               $color--white,
        dark:               darken($color--white, 10%)
    ),

    black: (
        base:               $color--black,
        light:              lighten($color--black, 30%)
    ),

    orange: (
        base:               $color--orange,
        light:              lighten($color--orange, 10%),
        dark:               darken($color--orange, 10%)
    ),

    grey: (
        base:               $color--grey,
        light:              #ECECEC,
        dark:               darken($color--grey, 10%)
    ),

    yellow: (
        base:               $color--yellow,
        light:              lighten($color--yellow, 10%),
        dark:               darken($color--yellow, 10%)
    )
);

// Breakpoints
$break--xsm: 325px;
$break--sm: 640px;
$break--md: 768px;
$break--lg: 892px;
$break--xlg: 1200px;

// Gutters
$gutter--md: 1.25rem;
$gutter--xsm: math.div($gutter--md, 4);
$gutter--sm: math.div($gutter--md, 2);
$gutter--lg: $gutter--md*2;
$gutter--xlg: $gutter--md*4;

// Grid
$grid--size: 1330px;
$grid--gutter: 30px;

// Icon sizes
$icon-size--base: 1.75rem;
$icon-size--sm: ($icon-size--base * 0.667);
$icon-size--md: ($icon-size--base * 1);
$icon-size--lg: ($icon-size--base * 1.334);

// Border radius
$border-radius--base: 0.25rem;
$border-radius--sm: ($border-radius--base * 0.5);
$border-radius--md: ($border-radius--base * 1);
$border-radius--lg: ($border-radius--base * 2);

// Fonts
$font-family--charter: "Charter", Times New Roman, serif;
$font-family--maquette: "ARS Maquette", Arial, sans-serif;
$font-family--base: $font-family--maquette;

$font-size--base: 1.125rem; // 18px
$font-size--xsm: 0.875rem; // 14px
$font-size--sm: 1rem; // 16px
$font-size--md: 1.5rem; // 24px
$font-size--lg: 2rem; // 32px
$font-size--xlg: 2.5rem; // 40px
$font-size--xxlg: 3rem; // 48px

//$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--bold: 500;
$font-weight--bolder: 700;

$line-height--base: 1;
$line-height--sm: ($line-height--base * 0.8334);
$line-height--md: ($line-height--base * 1.2);
$line-height--lg: ($line-height--base * 1.5);

$headings: 'h1', 'h2', 'h3', 'h4', 'h5', 'h6';

// Inputs
$input--text: 'input[type="text"], input[type="password"], input[type="search"], input[type="email"], input[type="tel"], input[type="url"], input[type="number"]';
$input--buttons: 'input[type="submit"], input[type="reset"], input[type="button"], button';
$input--dates: 'input[type^="date"], input[type="month"], input[type="week"], input[type="time"]';
$input--not-radio: 'input:not([type="radio"]):not([type="checkbox"])';
$inputs: "#{$input--text}, #{$input--buttons}, #{$input--dates}, #{$input--not-radio}, textarea, input[type="file"], input[type="range"]";

// Transitions
$transition--base: all 0.2s ease-in-out;
$transition--bezier: all 0.5s cubic-bezier(.2, 0, 0, 1);
$transition--opacity: opacity 0.2s ease-in-out;
$transition--bezier--timing: cubic-bezier(.2, 0, 0, 1);

// Selection
$selection--color: $color--white;
$selection--background: $color--orange;

// Z-index
$z-index: (
	"menu",
    "dropdown",
    "header",
	"overlay",
	"content"
);