$icons: "arrow_right", "arrow_up_sm", "arrow_up", "cross", "dropdown";
  
.icon {
  display: block;
  @extend .bg--100;
  flex-shrink: 0;
  @extend .icon--md;
  
  &--mask {

    @supports (mask: url("something.svg")) {
      background-color: $color--orange;
    }

    @each $icon in $icons {
      &-#{$icon} {
        @supports (mask: url("#{$icon-path}icon_#{$icon}.svg")) {
          mask-image: url("#{$icon-path}icon_#{$icon}.svg");
          -webkit-mask-image: url("#{$icon-path}icon_#{$icon}.svg");
          -ms-mask-image: url("#{$icon-path}icon_#{$icon}.svg");
          background-image: none;
          mask-size: cover;
        }
      }
    }    

  }
    
  @each $icon in $icons {
    &-#{$icon} {
      background-image: url("#{$icon-path}icon_#{$icon}.svg");
    }
  }

  // Sizes
  &--sm {
      width: $icon-size--sm;
      height: $icon-size--sm;
  }

  &--md {
      width: $icon-size--md;
      height: $icon-size--md;
  }

  &--lg {
      width: $icon-size--lg;
      height: $icon-size--lg;
  }
    
}
