img {
    width: 100%;
    max-width: 100%;
    height: auto;
    image-rendering: auto;
}

figure {
    width: 100%;
    max-width: 100%;
    display: block;
}

// media types
.media {
    @extend .bg--cover;
    position: relative;

    // Image type
    &-image {
        @include ratio(16,9);

        &--square {
            @include ratio(1,1);
        }
    }
    // Avatar images
    &-avatar {
        @include ratio(1,1);
        position: relative;

        img {
            object-fit: cover;
            @extend .p--abs--100;
            height: 100%;
            transition: $transition--bezier;
            transform: scale(1);
        }

        &:after {
            content: '';
            @extend .p--abs--100;
            background: color(orange, base);
            z-index: 2;
            mix-blend-mode: multiply;
        }
    }

    // Video type
    &-video {
        @include ratio(16,9);

        iframe {
            @extend .p--abs--100;
        }
    }
}
