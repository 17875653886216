.highlight {

  &-container {
    margin-top: -$gutter--md * 3;
    place-self: center;
    
    .header__subtitle,
    .header__title {
      @extend .display1;
    }
    
    @media (orientation: landscape) and (max-width: $break--lg) {
      margin-top: 0;
    }
  }

}