@font-face {
  font-family: 'ARS Maquette';
  src: url('#{$font-path}ARSMaquette-Regular.woff2') format('woff2'),
  url('#{$font-path}ARSMaquette-Regular.woff') format('woff');
  font-weight: $font-weight--normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ARS Maquette';
  src: url('#{$font-path}ARSMaquette-Medium.woff2') format('woff2'),
  url('#{$font-path}ARSMaquette-Medium.woff') format('woff');
  font-weight: $font-weight--bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ARS Maquette';
  src: url('#{$font-path}ARSMaquette-Bold.woff2') format('woff2'),
  url('#{$font-path}ARSMaquette-Bold.woff') format('woff');
  font-weight: $font-weight--bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Charter';
  src: url('#{$font-path}Charter-Roman.woff2') format('woff2'),
  url('#{$font-path}Charter-Roman.woff') format('woff');
  font-weight: $font-weight--normal;
  font-style: normal;
  font-display: swap;
}