.branding {
  &-logo {    
    @include ratio(256,130);
    position: relative;
    width: 100%;
    display: block;
    max-width: 126px;
    cursor: pointer;
    opacity: 0;
    animation: fadeIn 1s 0.5s linear forwards;
    
    @include breakpoint($break--lg, min) {
      @include center(false, true);
      position: sticky;
      max-width: 256px;
    }

    @include breakpoint($break--lg, max) {
      margin-top: $gutter--md;
    }

    &__img {
      @extend .p--abs--100;
      mask-image: url(#{$image-path}tci_logo_mask.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;

      &:after {
        content: "";
        background: color(black, base);
        position: absolute;
        top: 0;
        transition: $transition--base;
        width: 60%;
        height: 100%;
        left: 40%;
      }
    }
    
    &__circle {
      @extend .p--abs--100;
      mask-image: url(#{$image-path}tci_logo_mask.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      z-index: -1;
      
      &:after {
        content: "";
        background: color(orange, base);
        position: absolute;
        top: 50%;
        transition: $transition--bezier;
        transform: translate3d(100%,-50%,0);
        width: 50%;
        height: 98%;
        left: 0;
        border-radius: 50%;
        animation: slideIn 2s 1s $transition--bezier--timing forwards;
      }
    }
    
    &:hover {
      .branding-logo__circle:after { 
        background: color(orange, dark);
      }
    }
    
    &--light, &--dark {
      @extend .branding-logo;
    }

    @at-root .theme--dark .branding-logo__img:after {
      background-color: color(white, base);
    }

    @at-root .theme--light .branding-logo__img:after {
      background-color: color(black, base);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translate3d(100%,-50%,0);
  }
  
  100% {
    transform: translate3d(0%,-50%,0); 
  }
}
