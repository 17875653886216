.pagination {

  &-container {
    display: flex;
    list-style-type: none;
  }

  &__item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: $color--orange;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    min-width: 32px;
    font-weight: $font-weight--bold;
    transition: $transition--bezier;

    &:before {
      content: none;
    }

    &--dots:hover {
      background-color: transparent;
      cursor: default;
      color: $color--orange;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: $color--black;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}