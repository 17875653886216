.block {
  margin-bottom: $gutter--md;

  &--heading {
    padding-top: $gutter--lg;
  }

  &--text,
  &--list {
    margin-bottom: 0;
  }

  &--image {
    margin: $gutter--md*1.5 0 $gutter--lg*1.25;
  }

  &--video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
    
    & iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;      
    }
  }

}