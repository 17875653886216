.card {
  display: grid;
  grid-template-rows: max-content auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "h i" "d d";
  margin-bottom: $gutter--md*2.5;
  grid-row-gap: $gutter--md*2.5;
  grid-gap: $gutter--md;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($break--lg, max) {
    grid-template-columns: 2fr 1fr;
  }

  @at-root a#{&} {
    img {
      transform: scale(1.05);
    }
    
    &:hover {
      cursor: pointer;
      img {
        transform: scale(1);
      }
    }
  }
  
  a {
    background: none;
    
    img {
      transform: scale(1.05);
    }
    
    &:hover {
      cursor: pointer;
      img {
        transform: scale(1);
      }
      
      .card__title {
        background-size: 100% 0.1em;
      }
    }
  }

  &__section {

    & + & {
      margin-top: $gutter--lg * 1.25;
    }

    &__headline {
      margin-bottom: $gutter--md * 1.25;
    }
  }

  &__header {
    grid-area: h;
    place-self: end stretch;
    word-break: break-word;

    @at-root .card--event & {
      place-self: end stretch;
    }
  }

  &__img {
    margin-left: $gutter--lg;
    grid-area: i;
    place-self: start stretch;
    @extend .media-avatar;

    @include breakpoint($break--lg, max) {
      margin-left: 0;
    }

    &--non-multiply:after {
      // content: none !important;
    }

  }

  &__description {
    grid-area: d;
  }

  &__title {
    @at-root a & {
      @extend .h--link;
    }

    i {
      margin-bottom: 2px;
      display: inline-block;
      vertical-align: bottom;

      @include breakpoint($break--lg, max) {
        width: $icon-size--md;
        height: $icon-size--md;
        margin-bottom: 0;
      }
    }

  }

  &__subtitle {
    @extend .h--subtitle;
  }

  &__description {

    @at-root .card--teammember & {

      @include breakpoint($break--md, min) {
        display: grid;
        grid-template-columns: 5fr 2fr;        
      }      

    }
  }

  &--teammember {
    @extend .card;
  }

  &--teammember-compact {
    @extend .card;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

  &--partner,
  &--event {
    @extend .card;

    @include breakpoint($break--lg, min) {
      grid-template-columns: 5fr 2fr;
      grid-row-gap: 10px;
      grid-template-areas: "h i" "d i";
    }
  }

  &--news {
    @extend .card;
    grid-template-areas: "i ." "h h" "d d";
    grid-template-columns: 1fr 2fr;    

    @include breakpoint($break--lg, min) {
      grid-template-columns: 2fr 5fr;
      grid-row-gap: 20px;
      grid-template-areas: "i h" "i d";
    }

    .card__img {
      margin-left: 0;
      margin-right: $gutter--lg;      
    }
  }

  &--tile {
    @extend .card;
    grid-template-areas: "h h" "d d";
    grid-template-columns: 5fr 2fr;

    & > a {
      grid-column: 1/span 2;
      position: relative;

      &:hover {
        background-size: 0px;
      }
    }

    .card__description {
      grid-column: 1/span 1;
    }

    .card__img {
      margin-left: 0;
      aspect-ratio: 2/1;
    }

    .card__title {
      position: absolute;
      left: $gutter--md;
      bottom: $gutter--md;
      color: $color--white;
      z-index: 10;
    }

    i,
    .card__subtitle {
      display: none;
    }
  }

  &--inactive {
    filter: saturate(0);
    opacity: .25;
  }
}
