.theme {

  &--light,
  &--dark {
    transition: background-color .5s $transition--bezier--timing;
    will-change: background-color;
  }

  &--dark {
    background-color: color(black, base);
    color: color(white, base);
  }

  &--light {
    background-color: color(white, light);
    color: color(black, base);
  }

}

body.theme--dark {
//  overflow: hidden;
}