.nav {
  position: fixed;
  // width: 100%; 
  max-height: 100%;
  top: 0; 
  right: 0; 
  left: 0;
  margin: $gutter--lg;
  transform: translate3d(0,100%,0);
  background: $color--white;
  padding: 0 $gutter--lg * 1.25 $gutter--lg * 1.25;
  border-radius: 20px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  z-index: z-index('menu'); 
  max-height: calc(100% - #{$gutter--xlg});
  overflow: auto;
  will-change: all;
  display: grid;
  grid-template-areas: "header header" "search static";
  grid-gap: $gutter--md;

  @include breakpoint($break--md, max) {
    border-radius: 15px;
    padding: 0 $gutter--md $gutter--md;
    margin: $gutter--sm;
    bottom: 0;
    max-height: 100vh;
    grid-template-areas: "header" "static" "search";
  }

  &__close {
    position: absolute;
    top: $gutter--lg * 1.25;
    right: 0;
    cursor: pointer;

    @include breakpoint($break--lg, max) {
      top: $gutter--md;
      transform: scale(0.75);
      transform-origin: top right;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    background-color: rgba(color(white, base), .95);
    background: linear-gradient(rgba(color(white, base), 1), 80%, rgba(color(white, base), .0));
    padding-top: $gutter--lg * 1.25;
    padding-bottom: $gutter--md;
    grid-area: header;

    h4 {
      font-family: $font-family--charter;
      padding-bottom: $gutter--sm;
    }

    @include breakpoint($break--lg, max) {
      padding-top: $gutter--md;
      padding-bottom: 0;

      h4 {
        font-size: $font-size--sm;
      }
    }

  }

  &__static {
    margin-left: $gutter--lg;
    padding-left: $gutter--lg;
    border-left: 3px solid color(black, base);
    grid-area: static;
    display: flex;
    flex-direction: column;

    @include breakpoint($break--md, max) {
      margin-bottom: $gutter--sm;
      padding-bottom: $gutter--sm * 1.25;
      border-bottom: 2px solid color(black, base);
      margin-left: 0;  
      padding-left: 0;
      border-left: none;
    }

    a {
      width: 100%;
      display: block;
    }
  }

  &__search {
    // overflow: auto;
    grid-area: search;
    display: flex;
    flex-direction: column;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color:rgba(0, 0, 0, 0.7);
    z-index: z-index('menu');
    will-change: opacity;
  }

  a, button {
    font-size: #{"min((" + $font-size--lg + ")"}, #{"max(3vw, " + $font-size--md + "))"};
    line-height: $line-height--md;
    text-decoration: none;
    color: $color--black;
    transition: color .15s $transition--bezier--timing;
    margin-bottom: $gutter--sm;
    background-image: none !important;

    @include breakpoint($break--md, max) {
      margin-bottom: $gutter--sm;
    }

    &:hover,
    &.active {
      text-decoration: none;
      color: $color--orange;      
    }
    
  }

  button {
    border: none;
    background: transparent;
    font-weight: 500;
    font-family: inherit;
    padding: 0;
    text-align: left;
  }

}