@use "sass:math";

.view {
  min-height: 100%;
  grid-template-rows: min-content;
  grid-template-columns: calc(50vw - #{math.div($grid--size, 2)}) repeat(10, 1fr) calc(50vw - #{math.div($grid--size, 2)});
  grid-column-gap: $grid--gutter;
  grid-row-gap: $gutter--md * 2.5;
  grid-template-areas:
    "a a a d d d d . . ."
    "a a a m m m m m m m";

  // Fallbacks for browsers wdo do not support grid
  display: flex;
  padding: 0 calc(50vw - #{math.div($grid--size, 2)});
  flex-wrap: wrap;

  & > * {
    flex-basis: 100%;
  }

  @supports (display: grid) {
    display: grid;
    padding: 0;
  }

  @include breakpoint($break--lg, max) {
    grid-template-rows: min-content min-content;
    grid-template-columns: $gutter--md 1fr $gutter--md;
    grid-column-gap: 0;
    grid-template-areas:
      ". a ."
      ". d ."
      ". m .";
      grid-row-gap: 0;
  }
  
  @media (orientation: landscape) and (max-width: $break--lg) {
    grid-template-rows: min-content min-content;
    grid-template-columns: $gutter--md 1fr 1fr 1fr 1fr $gutter--md;
    grid-column-gap: 0;
    grid-template-areas:
      ". a d d d ."
      ". m m m m .";
      grid-row-gap: 0;
  }

  // view children
  &__dropdown {
    grid-template-columns: 4fr;
    grid-column: 5 / span 4;
    position: sticky;
    z-index: z-index('dropdown');

    @include breakpoint($break--lg, min) {
      top: 0 !important;
    }

    @include breakpoint($break--lg, max) {
      top: 0;
      grid-column: 2 / span 1;
    }
    
    @media (orientation: landscape) and (max-width: $break--lg) {
      top: 0;
      padding-left: $gutter--sm;
      grid-column: 3 / span 3;
    }
  }

  &__aside {
    grid-area: a;
    grid-template-columns: 3fr;
    grid-column: 2 / span 3;
    position: relative;
    z-index: z-index('content');

    @include breakpoint($break--lg, max) {
      grid-column: 2 / span 1;
    }
    
    @media (orientation: landscape) and (max-width: $break--lg) {
      grid-column: 2 / span 1;
    }

  }

  &__main {
    grid-area: m;
    grid-template-columns: 7fr;
    grid-column: 5 / span 7;
    padding-bottom: $gutter--lg * 2;

    @include breakpoint($break--lg, max) {
      grid-column: 2 / span 1;
      padding-top: $gutter--lg;
      padding-bottom: $gutter--lg;
    }
    
    @media (orientation: landscape) and (max-width: $break--lg) {
      grid-column: 2 / span 4;
      padding-top: $gutter--lg;
      padding-bottom: $gutter--lg;
    }
    
  }

  &__cards {
    margin-top: $gutter--lg * 1.25;
  }

  &--page {
    @extend .view;
  }

  &--landing {
    @extend .view;
    grid-template-rows: minmax(100vh, min-content);
    grid-template-areas: ". a h .";
    align-items: center;
    background-color: color(black, base);
  }

  &--blocks {
    min-height: auto;
    position: relative;
    will-change: opacity;
    margin-top: $gutter--md;
    display: grid;

    & .block--image, & .block--video {
      grid-column:  1 / span 2;
    }

    & * {
      grid-column:  1 / span 1;

      @include breakpoint($break--lg, max) {
        grid-column:  1 / span 2;
      }
    }

    @at-root .theme--dark & {
      padding: 0;
    }

  }
}
