.rings {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;

  div {
    position: absolute;
    border-radius: 50%;
    background: color(grey, base);
    will-change: transform;

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        width: calc(80vw - (5vw * #{$i}));
        height: calc(80vw - (5vw * #{$i}));
        background: color(grey, dark);
//        opacity: #{$i * .05};
        z-index: $i;
      }      
    }
  }

  &--pulse div {
    width: 60vw !important;
    height: 60vw !important;
  }
}