%dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: color(orange, base);
}

.dots {
  position: fixed;
  width: 200px;
  height: 200px;
  bottom: 0;  
  left: 50%;
  transform: translate3d(-50%,0,0);
  z-index: 2;
  overflow: hidden;
  transition: $transition--bezier;
  cursor: pointer;

  .dot {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    border-radius: 50%;
    background: color(orange, base);
    margin: $gutter--xsm;
    animation: dot 3s infinite cubic-bezier(0.2, 0, 0, 1);
    transition: $transition--bezier;
    opacity: 1;
    transform: translate3d(-50%, 200%,0);
    
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.6}s;
      }
    }
    
    @include breakpoint($break--sm, max) {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  
  &:hover {
    .dot {
      animation-duration: 1.5s;
    }
  }
}

@keyframes dot{
  0% {
    opacity: 1;
    transform: translate3d(-50%, 200%,0) scale(1);
  }
  
  100% {
    opacity: 0;
    transform: translate3d(-50%,-600%,0) scale(0.9);
  }
}