.contact-block {
  margin: $gutter--lg 0 $gutter--lg;


  &__name {
    border-bottom: 3px solid color(black, base);
    margin: $gutter--md 0;
    padding-bottom: $gutter--sm;

    @include breakpoint($break--lg, max) {
      margin: $gutter--md * 1.25 0;
    }
  }

  &__contact {
    display: flex;
    margin-bottom: $gutter--md;
    

    .card__header {
      flex-basis: 50%;
    }

    a {
      align-self: flex-end;
      margin-bottom: $gutter--sm * 0.75;
    }

    @include breakpoint($break--sm, max) {
      display: block;
    }
  }

}