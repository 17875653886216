.page {

  &__header {
    grid-column: 1 / span 3;

    figure {
      padding-bottom: $gutter--sm;

      &:last-child {
        padding-bottom: $gutter--lg * 1.25;
      }
    }

    figure + h2 {
      padding-bottom: $gutter--lg * 1.25;
    }

  }

  &__blocks {
    @extend .view;
    grid-area: m;
    grid-template-columns: 5fr 2fr;
    grid-template-areas: none;
    grid-row-gap: 0;
    grid-column: 1/span 1;
    min-height: auto;
  
    @include breakpoint($break--md, max) {
      grid-template-columns: 1fr;
    }
  }

  &__footer {
    grid-column: 1 / span 3;

    border-top: 3px solid $color--black;
    margin-top: $gutter--lg;
    padding-top: $gutter--md;
    text-align: right;

    @extend .card__title;

    a, h5 {
      @extend .h--link;
      display: inline-flex;
      align-items: center;

      i {
        margin-left: $gutter--sm;
      }
    }
  }

}