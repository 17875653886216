.landscape {
  position: absolute;
  width: 100%;
  height: calc(100% - #{($gutter--md * 3)});
  top: 0;
  left: 0;
  padding-bottom: $gutter--md * 3;

  @include breakpoint($break--lg, max) {
    margin-top: $gutter--md;  
    padding-bottom: $gutter--md;  
  }

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 80%;
  }

  &__entries {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__entry {    
    top: 0;
    left: 0;
//    justify-self: end;
    width: 25vw;
    min-width: 250px;
    max-width: 350px;
    cursor: pointer;
    margin-left: $gutter--md * 1.5;
    will-change: auto;
    
    &:not(&--selected):hover {
      
      &:before {
        background-color: white;
      }
    }

    &:nth-child(odd) {
//      justify-self: start;
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: color(orange, base);    
      position: absolute;
      top: 1.125rem;
      left: 0;
      transition: background .2s $transition--bezier--timing;

      @include breakpoint($break--md, max) {
        top: 1rem;
        width: 8px;
        height: 8px;
      }
    }

    @at-root .landscape--show-selected & {
      &:before {
        background: color(grey, base);      
      }

      &--selected {
        &:before {
          background: color(orange, base);              
        }
      }
  
    }

  }  

  &__grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-rows: auto;

    @at-root .landscape--show-selected & {
      @include breakpoint($break--lg, max) {
        grid-auto-rows: min-content;
        align-content: end;      
      }  
      
      @media (orientation: landscape) and (max-width: $break--lg) {
        align-content: start; 
      }
    }
    
    

    &-entry {
      justify-self: end;
      background-color: green;
      border: 1px solid white;
      visibility: hidden;

      .landscape__entry {
        visibility: hidden;
      }

      &:nth-child(odd) {
        justify-self: start;
      }

      @at-root .landscape--show-selected & {        
        justify-self: end;
        @include breakpoint($break--lg, max) {

          @media (orientation: portrait) {
            justify-self: start;            
          }

        }        
      }  

      @include breakpoint($break--lg, max) {
        margin-bottom: $gutter--sm;
      }

    }

    &--selected {
      position: absolute;
      display: grid;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      grid-template-columns: 1fr;
      align-items: center;     
      visibility: hidden;

      @at-root .landscape--show-selected & {
        @include breakpoint($break--lg, max) {
          grid-auto-rows: min-content;
        }
      }

      @include breakpoint($break--lg, max) {
        align-content: start !important;      
      }

      .landscape__entry {
        background: lightblue;      
      }
  
    }

  }

  &__title {
    color: currentColor;
    font-weight: $font-weight--bolder;
    font-size: #{"min((" + $font-size--md + ")"}, #{"max(3vw, " + $font-size--sm + "))"};    
//    font-size: $font-size--md;
  }

  &__subtitle {
    @extend .h--subtitle;    
    font-size: #{"min((" + $font-size--md + ")"}, #{"max(3vw, " + $font-size--sm + "))"};    
  }

  &__description {
    margin-top: $gutter--xsm;
    will-change: height;

    p {
      padding-bottom: $gutter--sm;
    }
  }  

}