.dropdown {
  padding: $gutter--md*0.9 $gutter--md*1.2;
  border-radius: $font-size--lg;
  background-color: color(white, base);
  box-shadow: 0px 12px 32px rgba(145, 120, 113, 0.25);
  margin-top: $gutter--lg * 1.25;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100vw - #{$gutter--md * 2});  
  will-change: transform;
  transition: box-shadow 0.2s ease-in-out;

  @include breakpoint($break--lg, max) {
    margin-top: $gutter--md;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(145, 120, 113, 0.3);
  }

  &:active {
    box-shadow: 0px 2px 4px rgba(145, 120, 113, 0.6);
  }

  @include breakpoint($break--sm, max) {
    padding: $gutter--sm*0.9 $gutter--md*1.2;    
  }

  span {
    font-size: $font-size--md * 0.875;
    font-family: $font-family--charter;  
    color: color(black, base);
    max-width: calc(100% - #{$icon-size--md});
    padding-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    @include breakpoint($break--sm, max) {
      font-size: $font-size--sm;
    }
  }

}