*, *::before, *::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

::selection {
  background: $selection--background;
  color: $selection--color;
}

::-moz-selection {
  background: $selection--background;
  color: $selection--color;
}

body, html, .base {
  background: color(white, light);
  color: color(black, base);
}

body, html, #root, .base {
  height: 100%;
}

body {
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
    overscroll-behavior: none;
  }
}

