* {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 16px;
}

body {
  font-family: $font-family--base;
  font-size: $font-size--base;
  font-weight: $font-weight--normal;
  line-height: $line-height--base;

  @include breakpoint($break--sm, max) {
    font-size: #{$font-size--base * 0.875};
  }
}

input,
textarea,
select,
button {
  font-size: $font-size--base;
}

#{$headings} {
  font-family: $font-family--maquette;
  font-weight: $font-weight--bold;
  line-height: $line-height--md;
  text-transform: none;
  padding: 0;
  margin: 0;
  color: color(black, base);
}

.display1 {
  font-size: #{"min((" + $font-size--xxlg + ")"}, #{"max(7vh, " + $font-size--xlg + "))"};
  font-weight: $font-weight--bold;

  @include breakpoint($break--lg, max) {
    font-size: #{"min((" + $font-size--lg + ")"}, #{"max(7vh, " + $font-size--md + "))"};    
  }
}

h1, .h1 {
  font-size: #{"min((" + $font-size--xlg + ")"}, #{"max(7vh, " + $font-size--lg + "))"};
  font-weight: $font-weight--bold;

  @include breakpoint($break--lg, max) {
    font-size: #{"min((" + $font-size--md + ")"}, #{"max(7vh, " + $font-size--sm + "))"};    
  }
}

h2, .h2 {
  font-size: #{"min((" + $font-size--xlg + ")"}, #{"max(7vh, " + $font-size--lg + "))"};
  font-family: $font-family--maquette;

  @include breakpoint($break--lg, max) {
    font-size: #{"min((" + $font-size--md + ")"}, #{"max(7vh, " + $font-size--sm + "))"};    
  }
}

h3, .h3 {
  font-size: #{$font-size--lg * 1.125};

  @include breakpoint($break--lg, max) {
    font-size: $font-size--md;
  }
}

h4 {
  font-size: $font-size--lg;

  @include breakpoint($break--lg, max) {
    font-size: $font-size--md;
  }
}

h5 {
  font-size: $font-size--md;

  @include breakpoint($break--lg, max) {
    font-size: $font-size--base;
  }
}

h6 {
  font-size: $font-size--base;

  @include breakpoint($break--lg, max) {
    font-size: $font-size--sm;
  }
}

p {
  font-size: $font-size--base;
  padding-bottom: $gutter--md;
  line-height: $line-height--lg;
  white-space: pre-line;

  @include breakpoint($break--sm, max) {
    font-size: #{$font-size--base * 0.875};
  }

}

small {
  font-size: $font-size--xsm;
}

label {
  font-size: $font-size--xsm;
  font-family: $font-family--base;
  font-weight: $font-weight--bold;
  text-transform: uppercase;
}

ol,
ul {
  li {
    @extend p;
    counter-increment: li;
    margin-left: $gutter--lg;
    display: flex;
    padding-bottom: 0;

    &:before {
      content: "";
      color: color(orange, base);
      display: inline-block;
      margin-left: -$gutter--lg;
      flex: 0 0 2.5rem;
    }
  }
}

ul li:before {
  content: "\2022";
}

ol li:before {
  content: counters(li, ".") ". ";
}

a, .link, .article a, a .card__title, a:focus {
  cursor: pointer;
  color: color(orange, base);
  font-weight: $font-weight--bold;
  text-decoration: none;
  background-image: linear-gradient($color--orange, $color--orange);
  background-repeat: no-repeat;
  background-position: 0 97%;
  background-size: 0 0.1em;
  transition: background 0.4s cubic-bezier(.2, 0, 0, 1) !important;
  padding-bottom: 0.1em;
  display: inline;
  
  &:hover {
    background-size: 100% 0.1em;
  }

  &:active,
  &:focus {
    color: color(orange, base);
  }

}

b, strong {
  font-weight: $font-weight--bolder;
  line-height: $line-height--lg;
}

time {
  line-height: $line-height--lg;
}

i, em {
  font-style: italic;
}

blockquote {

  p {

  }

  footer {

  }

  cite {

  }
}

pre {
  white-space: pre;
}

code {
  font-size: $font-size--sm;
  line-height: $line-height--lg;
  color: color(black, light);
}

figcaption {
  font-size: $font-size--sm;
  color: $color--grey;
  margin-top: $gutter--sm;
}

.h {
  &--subtitle {
    font-family: $font-family--charter;
    color: color(grey, base);
    font-weight: $font-weight--bold;
  }

  &--link {
    color: color(orange, base);

    @at-root a[disabled] & {
      color: color(grey, base);
    }
  }
}